<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    v-model="tabIndex"
  >
    <b-tab lazy>
        <template #title>
            <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
            />
            <span class="font-weight-bold">General</span>
        </template>
        <b-card>
            <b-form>
                <b-row>
                    <b-col sm="6">
                        <b-form-group
                            label="Correo electronico"
                            label-for="account-username"
                        >
                            <b-form-input
                                v-model="mixing.email"
                                disabled
                                placeholder="Email"
                                name="username"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group
                            label="Nombre completo"
                            label-for="account-name"
                        >
                            <b-form-input
                                v-model="mixing.name"
                                disabled
                                name="name"
                                placeholder="Nombre"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group
                            label="Rol"
                            label-for="account-e-mail"
                        >
                            <b-form-input
                                disabled
                                v-model="mixing.rol"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-tab>

    <b-tab lazy>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Actualizar Contraseña</span>
      </template>
        <b-card>
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <!-- old password -->
                        <b-col md="6">
                        <b-form-group
                            label-for="account-old-password"
                        >
                            <label >Contraseña anterior <span class="text-danger">(*)</span></label>
                            <validation-provider
                                #default="{ errors }"
                                name="Contraseña anterior"
                                rules="required"
                            >
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                    <b-form-input
                                        id="account-old-password"
                                        v-model="passwordValueOld"
                                        :state="errors.length > 0 ? false:null"
                                        :type="passwordFieldTypeOld"
                                        placeholder="Contraseña anterior"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                        :icon="passwordToggleIconOld"
                                        class="cursor-pointer"
                                        @click="togglePasswordOld"
                                    />
                                    </b-input-group-append>
                                </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.old_password" :key="error" class="text-danger">{{ error }}</small>
                            </validation-provider>
                        </b-form-group>
                        </b-col>
                        <!--/ old password -->
                    </b-row>
                    <b-row>
                        <!-- new password -->
                        <b-col md="6">
                        <b-form-group
                            label-for="account-new-password"
                        >
                            <label >Nueva contraseña <span class="text-danger">(*)</span></label>
                            <validation-provider
                                #default="{ errors }"
                                name="Nueva contraseña"
                                vid="Password"
                                rules="required|min:8|password"
                            >
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                    <b-form-input
                                        id="account-new-password"
                                        v-model="newPasswordValue"
                                        :state="errors.length > 0 ? false:null"
                                        :type="passwordFieldTypeNew"
                                        placeholder="Nueva contraseña"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                        :icon="passwordToggleIconNew"
                                        class="cursor-pointer"
                                        @click="togglePasswordNew"
                                        />
                                </b-input-group-append>
                                </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small v-for="error in errores.password" :key="error" class="text-danger">{{ error }}</small>
                            </validation-provider>
                        </b-form-group>
                        </b-col>
                        <!--/ new password -->

                        <!-- retype password -->
                        <b-col md="6">
                        <b-form-group
                            label-for="account-retype-new-password"
                        >
                            <label >Confirme la nueva contraseña <span class="text-danger">(*)</span></label>
                            <validation-provider
                                #default="{ errors }"
                                name="Confirme la nueva contraseña"
                                rules="required|confirmed:Password"
                            >
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                    <b-form-input
                                        id="account-retype-new-password"
                                        v-model="RetypePassword"
                                        :state="errors.length > 0 ? false:null"
                                        :type="passwordFieldTypeRetype"
                                        placeholder="Nueva contraseña"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                        :icon="passwordToggleIconRetype"
                                        class="cursor-pointer"
                                        @click="togglePasswordRetype"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        </b-col>
                        <!--/ retype password -->

                        <!-- buttons -->
                        <b-col cols="12">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mt-1 mr-1"
                            @click="validar()"
                        >
                            Guardar
                        </b-button>
                        </b-col>
                        <!--/ buttons -->
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </b-tab>

  </b-tabs>
</template>

<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from "@validations";
import { BTabs, BTab, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        passwordValueOld: '',
        newPasswordValue: '',
        RetypePassword: '',
        passwordFieldTypeOld: 'password',
        passwordFieldTypeNew: 'password',
        passwordFieldTypeRetype: 'password',
        errores:[],
        tabIndex: 0,
    }
  },
  watch: {
    tabIndex: function(val) {
      if (val == 0) {
        this.newPasswordValue='';
        this.passwordValueOld='';
        this.RetypePassword='';
        this.$refs.simpleRules.reset();
        this.errores=[];
      }
    },
    newPasswordValue: function () { 
        this.errores.password=[];
    },
    passwordValueOld: function () { 
        this.errores.old_password=[];
    },
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validar() {
        this.$refs.simpleRules.validate().then(success => {
            if (success) {
                this.update_password();
            }
        })
    },
    update_password(){
        axiosIns.post('/admin/usuario/password-perfil',{
        'password':	this.newPasswordValue,
        'old_password':	this.passwordValueOld,
        })
        .then(res => {
            if (res.data==1) {
                this.$bvToast.toast('Password se actualizo correctamente.', {
                    title: 'Contraseña fue actualizada',
                    variant:'primary',
                    solid: false,
                })
                this.newPasswordValue='';
                this.passwordValueOld='';
                this.RetypePassword='';
                this.$refs.simpleRules.reset();
                this.errores=[];
            }else{
                this.$bvToast.toast('La contraseña anterior es incorrecto.', {
                    title: 'Contraseña incorrecto',
                    variant:'danger',
                    solid: false,
                });
                this.errores={
                    old_password:["La contraseña anterior es incorrecto"]
                };
            }
            
        })
        .catch(err =>{
            this.errores=err.response.data.errors;
            this.$bvToast.toast('La contraseña nueva tiene que ser diferente a la contraseña anterior.', {
                title: 'Contraseña incorrecto',
                variant:'danger',
                solid: false,
            })
        });
    },
  },
  beforeCreate() {
    //this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
